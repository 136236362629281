import React from 'react';
import { Icons, NamedLink } from '../../../components';

import css from '../SearchPage.module.css';

function SectionBanner() {
  return (
    <div className={css.searchPageBanner}>
      <div className={css.breadcrumbs}>
        <NamedLink className={css.link} name="LandingPage">
          Home
        </NamedLink>
        <Icons name="nextArrow" />
        <NamedLink className={css.link} name="SearchPage">
          Shop
        </NamedLink>
      </div>
      <h1>Shop</h1>
      <p>
        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis voluptatum
        deleniti.
      </p>
    </div>
  );
}

export default SectionBanner;
